<template>
  <div>
    <div class="page-section-alt">
      <banner :title="meta.title" />

      <v-container>
        <v-row>
          <v-col class="pb-12 mb-12">
            <h2>VERİ SORUMLUSU</h2>
            <p>
              6698 sayılı Kişisel Verilerin Korunması Kanunu (KVKK) uyarınca,
              veri sorumlusu sıfatıyla “Kadın Hekimler Eğitime Destek Vakfı
              (KAHEV)” tarafından “www.kahev.org” ve “www.kahev.org.tr” web
              sitesi üzerinden birtakım kişisel verileriniz işlenmektedir.
            </p>
            <h2>KİŞİSEL VERİLERİN İŞLENME AMAÇLARI</h2>
            <p>
              Vakıf üyelerimizin “üye girişi – paneli” sekmesinden e-posta
              adresi ve kullanıcı şifresi girmek suretiyle üyelik işlemlerini;
              vakfımıza üye olmak isteyen kişilerin de “üyelik başvurusu”
              sekmesinden başvuru işlemlerini web sitesi üzerinden
              gerçekleştirme imkânı bulunmaktadır. Vakıf üyelerimizin ve üyelik
              başvurusu yapan kişilerin işlenen kişisel verilerinin neler
              olduğuna ilişkin detaylı bilgi almak için web sitemizin “KVKK
              Aydınlatma Metinleri” sekmesi altında Üyelere Yönelik Aydınlatma
              Metnini inceleyebilirsiniz.
            </p>
            <p>
              Web sitemizde yer alan iletişim formu aracılığıyla vakfımızla
              iletişim kurulması mümkündür. Bu iletişim formu aracılığıyla vakıf
              üyesi olsun ya da olmasın iletişim formu dolduran kişilerin adı,
              soyadı, telefon numarası, e-posta adresi verileri işlenmektedir.
              Bu veriler;
            </p>
            <ol>
              <li>Müşteri ilişkileri yönetimi süreçlerinin yürütülmesi,</li>
              <li>Talep ve şikayetlerin takibi</li>
              <li>İletişim faaliyetlerinin yürütülmesi,</li>
            </ol>
            <p>Amaçlarıyla sınırlı olarak işlenmektedir.</p>

            <p>
              Web sitesini ziyaret eden kişilerin işlem güvenliğine ilişkin de
              birtakım bilgileri işlenmektedir. Web sitemizi ziyaret eden
              müşterilerimizin IP adres bilgileri, internet sitesi giriş çıkış
              bilgileri işlenmektedir. Bu veriler;
            </p>
            <ol>
              <li>Yönetim faaliyetlerinin yürütülmesi,</li>
              <li>Bilgi güvenliği süreçlerinin yürütülmesi,</li>
              <li>Faaliyetlerin mevzuata uygun yürütülmesi,</li>
              <li>Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi,</li>
            </ol>
            <p>Amaçlarıyla sınırlı olarak işlenmektedir.</p>
            <p>
              Veri sorumlusuna ait web sitesi (www.kahev.org) üzerinden web
              sitesini ziyaret eden kullanıcıların tanıtım, kampanya
              süreçlerinin yürütülmesi ve analizi için çerez (cookie) bilgileri
              de işlenmektedir. Çerez politikamız ile ilgili detaylı bilgileri
              web sitesinde ilgili sekmenin altında yer alan metinde
              bulabilirsiniz.
            </p>
            <h2>KİŞİSEL VERİ İŞLENMESİNİN HUKUKİ SEBEBİ</h2>
            <p>
              Bu kişisel veriler, 6698 Sayılı Kanun’un 5. Maddesinin 2.
              Fıkrasının;
            </p>
            <ul>
              <li>(a) bendine (kanunlarda açıkça öngörülmesi),</li>
              <li>
                (c) bendine (bir sözleşmenin kurulması veya ifasıyla doğrudan
                doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait
                kişisel verilerin işlenmesinin gerekli olması);
              </li>
              <li>
                (ç) bendine (veri sorumlusunun hukuki yükümlülüğünü yerine
                getirebilmesi için zorunlu olması),
              </li>
              <li>
                (f) bendine (ilgili kişinin temel hak ve özgürlüklerine zarar
                vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için veri
                işlenmesinin zorunlu olması)
              </li>
            </ul>
            <p>Hukuki sebeplerine dayalı olarak işlenmektedir.</p>
            <h2>KİŞİSEL VERİLERİN TOPLANMA YÖNTEMİ</h2>
            <p>
              Bu kişisel veriler, yukarıda belirtilen hukuki sebeplere dayalı
              olarak web sitesi aracılığıyla otomatik yollarla işlenmektedir.
            </p>
            <h2>KİŞİSEL VERİLERİN AKTARILMASI</h2>
            <p>
              Veri sorumlusu tarafından otel faaliyetleri çerçevesinde işlenen
              tüm bu kişisel veriler, ilgili kamu kurumları ve talep halinde
              adli merciler dışında üçüncü kişiler ile paylaşılmamaktadır. Bahse
              konu kişisel veriler hiçbir şekilde yurtdışına aktarılmamaktadır.
            </p>
            <p>
              İşlenen tüm kişisel veriler KVKK hükümlerine uygun idari ve teknik
              tedbirlerin alınması suretiyle muhafaza edilmektedir.
            </p>
            <h2>İLGİLİ KİŞİNİN HAKLARI</h2>
            <p>
              Kanunun “ilgili kişinin haklarını düzenleyen” 11. Maddesi
              kapsamında veri sorumlusuna başvurarak kendisiyle ilgili;
            </p>
            <ol>
              <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
              <li>Kişisel veri işlenmişse buna ilişkin bilgi talep etme,</li>
              <li>
                Kişisel verilerin işlenme amacını ve bunların amacına uygun
                kullanılıp kullanılmadığını öğrenme,
              </li>
              <li>
                Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı
                üçüncü kişileri bilme,
              </li>
              <li>
                Kişisel verilerin eksik veya yanlış işlenmiş olması halinde
                bunların düzeltilmesini isteme,
              </li>
              <li>
                Kanunun 7. maddesinde belirtilen şartlar çerçevesinde kişisel
                verilerin silinmesini veya yok edilmesini isteme,
              </li>
              <li>
                (d) ve (e) bentleri uyarınca yapılan işlemlerin, kişisel
                verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
              </li>
              <li>
                İşlenen kişisel verilerin münhasıran otomatik sistemler
                vasıtasıyla analiz edilmesi suretiyle kendi aleyhinize bir
                sonucun ortaya çıkmasına itiraz etme,
              </li>
              <li>
                Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle
                zarara uğraması halinde zararın giderilmesini talep etme,
              </li>
            </ol>
            <p>Haklarına sahiptir.</p>
            <p>
              “Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ
              Uyarınca”;
            </p>
            <ul>
              <li>
                Veri sahipleri bu taleplerini Türkçe olarak iletmek zorundadır.
              </li>
              <li>
                Taleplerin yazılı olarak veya ilgili kişi tarafından veri
                sorumlusuna daha önce bildirilen ve veri sorumlusunun sisteminde
                kayıtlı bulunan elektronik posta adresinin kullanılması
                suretiyle iletilmesi gerekmektedir.
              </li>
              <li>
                Başvurular yukarıda anılan tebliğin 5. Maddesinin 2. Fıkrasında
                sayılan zorunlu unsurları barındırmalıdır. Aksi takdirde
                yapılacak başvurular değerlendirmeye alınmayacaktır.
              </li>
            </ul>
            <h2>VERİ SORUMLUSU İLETİŞİM BİLGİLERİ</h2>
            <ul>
              <li>
                <strong>Yazılı Başvuru İçin Adres:</strong> Hoşnudiye Mah. İsmet
                İnönü 1 Caddesi No 74/11 Tepebaşı/Eskişehir
              </li>
              <li><strong>E-Posta:</strong> info@kahev.org.tr</li>
              <li><strong>Telefon:</strong> +90 222 231 60 20</li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import Banner from "./Banner.vue";

export default {
  components: {
    Banner
  },

  data() {
    return {
      meta: {
        title: "KAHEV KVKK Aydınlatma Metni"
      }
    };
  },

  metaInfo() {
    const title = this.meta.title;

    return {
      title: title
    };
  }
};
</script>

<style lang="scss" scoped>
ol,
ul {
  margin-bottom: 18px;
}

ol {
  list-style-type: lower-latin;
}
</style>
